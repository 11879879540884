<!-- 这个页面没用   废弃了  自己写的内容 -->
<template>
   
  <div>
    <div class="banner">
      <div class="word1">案列展示55</div>
      <div class="txt1">领先的智能化碳信息技术平台</div>
    </div>

    <swiper :options="swiperOption" class="swiper-container" ref="mySwiper">
      <!-- //动态添加的 -->
      <swiper-slide class="li1">
        <img
          src="../../assets/AboutUs/d1f9d57a5cc219e0d5ef8e95891a1ad.jpg"
          alt
        />
      </swiper-slide>
      <swiper-slide class="li1"> 2 </swiper-slide>
      <swiper-slide class="li1"> 3 </swiper-slide>
      <swiper-slide class="li1"> 4 </swiper-slide>
      <swiper-slide class="li1"> 5 </swiper-slide>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <!-- 指示点 -->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <el-carousel :interval="4000" type="card" height="400px">
      <el-carousel-item>
        <img src="../../assets/AboutUs/pdf2.png" alt />
      </el-carousel-item>
      <el-carousel-item>
        <img
          src="../../assets/AboutUs/704c6537d9c6abd0fc7fb65edc22078.jpg"
          alt
        />
      </el-carousel-item>
      <el-carousel-item>
        <img
          src="../../assets/AboutUs/d1f9d57a5cc219e0d5ef8e95891a1ad.jpg"
          alt
        />
      </el-carousel-item>
      <el-carousel-item>
        <img
          src="../../assets/AboutUs/f721857609081dfd8cf76f8a43a1809.jpg"
          alt
        />
      </el-carousel-item>
      <el-carousel-item>
        <img
          src="../../assets/AboutUs/8b4f4ae5adb5258e1db9ff458b2d35d.jpg"
          alt
        />
      </el-carousel-item>
    </el-carousel>

    <div class="horizontally">
      <newsDetails></newsDetails>
    </div>
  </div>
</template>

<script>
import newsDetails from "../journalism/components/newsDetails.vue";

export default {
  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        slidesPerView: 5,
        grid: {
          fill: "column",
          rows: 1,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: true,
      },
    };
  },

  components: { newsDetails },

  mounted() {},
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {},
};
</script>
<style lang='less' scoped>
@import "./index.less";
/deep/.swiper {
  width: 600px;
  height: 300px;
  background: red;
}
// .swiper-container {
//   width: 1200px;
//   height: 500px;
//   margin: 0 auto;
//   margin-top: 70px;
//   --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
//   --swiper-navigation-color: #00ff33; /* 单独设置按钮颜色 */
//   --swiper-navigation-size: 30px; /* 设置按钮大小 */
// }

.li1 {
  img {
    width: 100%;
  }
  // width: 100% !important;
  background-color: red;
  // margin-top: 66px;
  // margin-right: 20px;
  // img {
  //   width: 100%;
  // }
}

.el-carousel {
  position: relative;
  width: 1200px;
  height: 500px;
  margin: 0 auto;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.el-carousel__item {
  text-align: center;
  img {
    width: 300px;
    height: 394px;
  }
}

li {
  img {
    width: 100%;
  }
}

.li1 {
  width: 200px;

  margin-top: 66px;
  margin-right: 20px;
}

.li2 {
  width: 258px;
  height: 338px;

  margin-top: 28px;
  margin-right: 20px;
}

.li3 {
  width: 300px;
  height: 394px;

  margin-right: 20px;
}
</style>
