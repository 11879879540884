<!-- 新闻详情 -->
<template>
  <div class="phone_details">
    <div class="div_header" v-if="equipment == 0 && type">新闻列表 > 新闻详情</div>
    <p class="p_title" v-if="type">{{ news_content.headLine }}</p>
    <div style="position: relative; margin: 0 0 10px 0" v-if="type">
      <span v-if="equipment == 1" class="span_tag">
        {{
        news_content.titleName
        }}
      </span>
      <div
        class="div_header_content"
        v-if="equipment == 0"
      >作者：{{ news_content.author }} &nbsp; 发布于：{{ news_content.time }} &nbsp; 浏览量：{{news_content.pvcount}}</div>
      <div
        class="div_header_content"
        v-if="equipment == 1"
      >作者：{{ news_content.author }} &nbsp; {{news_content.pvcount}}次浏览 &nbsp; {{ news_content.date }}</div>
    </div>
    <p v-html="news_content.content" class="content content_news" v-if="type"></p>
    <p v-html="news_content.productContent" class="content content_p" v-if="!type"></p>
  </div>
</template>

<script>
import { articleAddpv } from '../../../api/api'
export default {
  props: {
    news_val: {
      type: Object,
    },
    type: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      news_content: {},
      screenWidth: document.body.clientWidth,
      equipment: 0, //0 pc  1 移动
    };
  },
  watch: {
    news_val (val, old) {
      if (val) {
        this.news_content = val;
        articleAddpv({ articleId: val.aid }).then(res => {

        })
      }
    },
    $route (to, from) {

    },
    screenWidth (val) {
      this.screenWidth = val;
      this.load_equipment();
    },
  },
  components: {},
  mounted () {
    _hmt.push(['_trackEvent', '新闻详情', '查看']);
    this.init();
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    this.load_equipment();
    if (localStorage.getItem("details")) {
      this.news_content = JSON.parse(localStorage.getItem("details"));
    }
  },
  methods: {
    // 判断设备
    load_equipment () {
      if (this.screenWidth < 1024) {
        this.equipment = 1;
      }
    },
    init () {
      if (this.$route.path == "/CaseDetails") {

      }
    },
  },
};
</script>
<style lang='less' scoped>
@import '../style/newsDetails.less';
</style>
